import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AttachmentType,
  LeadAttachmentCreate,
  LeadAttachmentDetail,
  LeadAttachmentParams,
  UpdateLeadAttachment
} from '@core/models/interfaces/attachment';
import { ApiBasicParams, ResponseApi, ResponseListApi } from '@core/models/interfaces/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {
  attachmentUrl = `${environment.API_URL}/crm/attachment`;
  attachmentTypeUrl = `${environment.API_URL}/crm/attachment-type`;

  constructor(private http: HttpClient) {}

  getAttachmentTypes(param?: ApiBasicParams) {
    let queryParams = new HttpParams();
    if (param?.page !== undefined) {
      queryParams = queryParams.append('page', param.page);
    }
    if (param?.size !== undefined) {
      queryParams = queryParams.append('size', param.size);
    }
    if (param?.sorts !== undefined) {
      queryParams = queryParams.append('sort', param.sorts);
    }
    if (param?.keyword !== undefined) {
      queryParams = queryParams.append('keyword', param.keyword);
    }

    return this.http.get<ResponseListApi<AttachmentType>>(this.attachmentTypeUrl, {
      params: queryParams
    });
  }

  createLeadAttachment(body: LeadAttachmentCreate) {
    return this.http.post<ResponseApi<LeadAttachmentDetail[]>>(this.attachmentUrl, body);
  }

  /* LEAD ATTACHMENT  */

  getAttachmentPage(params?: LeadAttachmentParams) {
    let queryParams = new HttpParams();

    if (params?.leadId !== undefined) {
      queryParams = queryParams.append('leadId', params?.leadId);
    }
    if (params?.keyword !== undefined) {
      queryParams = queryParams.append('keyword', params?.keyword);
    }
    if (params?.typeIds) {
      for (let id of params?.typeIds) {
        queryParams = queryParams.append('typeIds', id);
      }
    }

    if (params?.addByIds) {
      for (let id of params?.addByIds) {
        queryParams = queryParams.append('addByIds', id);
      }
    }
    if (params?.policyInfoIds) {
      for (let id of params?.policyInfoIds) {
        queryParams = queryParams.append('policyInfoIds', id);
      }
    }
    if (params?.page !== undefined) {
      queryParams = queryParams.append('page', params?.page);
    }
    if (params?.size !== undefined) {
      queryParams = queryParams.append('size', params?.size);
    }
    if (params?.sorts !== undefined) {
      queryParams = queryParams.append('sorts', params?.sorts);
    }
    return this.http.get<ResponseListApi<LeadAttachmentDetail>>(this.attachmentUrl, {
      params: queryParams
    });
  }

  deleteAttachmentById(id: string) {
    return this.http.delete(`${this.attachmentUrl}/${id}`);
  }

  getAttachmentById(id: string) {
    return this.http.get<ResponseApi<LeadAttachmentDetail>>(`${this.attachmentUrl}/${id}`);
  }

  updateAttachmentById(id: string, body: UpdateLeadAttachment) {
    return this.http.put(`${this.attachmentUrl}/${id}`, body);
  }
}
