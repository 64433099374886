import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { ApiBasicParams, ResponseListApi } from '@core/models/interfaces/common';
import { TaskType } from '@core/models/interfaces/task';

@Injectable({
  providedIn: 'root'
})
export class TaskTypeService {
  private baseUrl = `${environment.API_URL}/crm/task-types`;

  constructor(private httpClient: HttpClient) {}

  getTaskTypes(params?: ApiBasicParams) {
    let queryParams = new HttpParams();

    if (params?.sorts !== undefined) {
      queryParams = queryParams.append('sorts', params.sorts);
    }

    if (params?.size !== undefined) {
      queryParams = queryParams.append('size', params.size);
    }

    if (params?.page !== undefined) {
      queryParams = queryParams.append('page', params.page);
    }

    if (params?.keyword !== undefined) {
      queryParams = queryParams.append('keyword', params.keyword);
    }

    return this.httpClient.get<ResponseListApi<TaskType>>(this.baseUrl, { params: queryParams });
  }
}
