{
  "name": "dream-talent",
  "version": "CRM 1.01.0608",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start:dev": "ng s --ssl=true",
    "build": "ng build",
    "build:qa": "ng build --configuration qa",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.0",
    "@angular/cdk": "^17.3.0",
    "@angular/common": "^17.3.0",
    "@angular/compiler": "^17.3.0",
    "@angular/core": "^17.3.0",
    "@angular/forms": "^17.3.0",
    "@angular/platform-browser": "^17.3.0",
    "@angular/platform-browser-dynamic": "^17.3.0",
    "@angular/router": "^17.3.0",
    "@ctrl/ngx-emoji-mart": "^9.2.0",
    "@fullcalendar/angular": "^6.1.15",
    "@fullcalendar/core": "^6.1.15",
    "@fullcalendar/interaction": "^6.1.15",
    "@fullcalendar/list": "^6.1.15",
    "@fullcalendar/timegrid": "^6.1.15",
    "@ngrx/effects": "^17.2.0",
    "@ngrx/store": "^17.2.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "chart.js": "^4.4.4",
    "chartjs-plugin-annotation": "^3.0.1",
    "d3": "^7.9.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.1.3",
    "i": "^0.3.7",
    "install": "^0.13.0",
    "lodash": "^4.17.21",
    "moment": "^2.30.1",
    "moment-timezone": "^0.5.45",
    "ng-apexcharts": "^1.8.0",
    "ng2-pdfjs-viewer": "^17.0.3",
    "ngx-doc-viewer": "^15.0.1",
    "ngx-infinite-scroll": "^17.0.0",
    "ngx-mask": "^18.0.0",
    "ngx-masonry": "^14.0.1",
    "ngx-permissions": "^17.1.0",
    "npm": "^10.8.1",
    "prettier-eslint": "^16.3.0",
    "primeicons": "^7.0.0",
    "primeng": "^17.17.0",
    "quill": "^2.0.2",
    "rxjs": "~7.8.0",
    "save-dev": "^0.0.1-security",
    "swiper": "^11.1.9",
    "tslib": "^2.3.0",
    "xlsx": "^0.18.5",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.1",
    "@angular-eslint/builder": "17.4.1",
    "@angular-eslint/eslint-plugin": "17.4.1",
    "@angular-eslint/eslint-plugin-template": "17.4.1",
    "@angular-eslint/schematics": "17.4.1",
    "@angular-eslint/template-parser": "17.4.1",
    "@angular/cli": "^17.3.1",
    "@angular/compiler-cli": "^17.3.0",
    "@types/d3": "^7.4.3",
    "@types/jasmine": "~5.1.0",
    "@types/lodash": "^4.17.13",
    "@typescript-eslint/eslint-plugin": "7.8.0",
    "@typescript-eslint/parser": "7.8.0",
    "autoprefixer": "^10.4.19",
    "eslint": "^8.57.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.38",
    "prettier": "^3.2.5",
    "tailwindcss": "^3.4.3",
    "typescript": "~5.4.2"
  }
}
